import React from "react";
import Layout from "../components/Layout/Layout";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import Hero from "../components/Home/Hero";
import Features from "../components/Home/Features";
import HalfImage from "../components/Home/HalfImage";
import HomeCarousel from "../components/Home/HomeCarousel";
import ProblemCounter from "../components/Home/ProblemCounter";

import "../styles/pages/home.scss";

export default function Home(props) {
  return (
    <Layout>
      <Hero />
      <Features />
      <HalfImage />
      <HomeCarousel />
      <ProblemCounter />
    </Layout>
  );
}
